/* Coolors Exported Palette - https://coolors.co/22272B-e8e7e6-290025-9792e3-84b39a */

:root {
    /* CSS HEX */
    --gunmetal: #22272Aff;
    --platinum: #e8e7e6ff;
    --dark-purple: #290025ff;
    --tropical-indigo: #9792e3ff;
    --cambridge-blue: #84b39aff;

    /* CSS HSL */
    --gunmetal: hsla(207, 11%, 15%, 1);
    --platinum: hsla(30, 4%, 91%, 1);
    --dark-purple: hsla(306, 100%, 8%, 1);
    --tropical-indigo: hsla(244, 59%, 73%, 1);
    --cambridge-blue: hsla(148, 24%, 61%, 1);
}

/* SCSS HEX */
$gunmetal: #22272Aff;
$platinum: #e8e7e6ff;
$dark-purple: #290025ff;
$tropical-indigo: #9792e3ff;
$cambridge-blue: #84b39aff;

/* SCSS HSL */
$gunmetal: hsla(207, 11%, 15%, 1);
$platinum: hsla(30, 4%, 91%, 1);
$dark-purple: hsla(306, 100%, 8%, 1);
$tropical-indigo: hsla(244, 59%, 73%, 1);
$cambridge-blue: hsla(148, 24%, 61%, 1);

/* SCSS RGB */
$gunmetal: rgba(34, 39, 42, 1);
$platinum: rgba(232, 231, 230, 1);
$dark-purple: rgba(41, 0, 37, 1);
$tropical-indigo: rgba(151, 146, 227, 1);
$cambridge-blue: rgba(132, 179, 154, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-right: linear-gradient(90deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-bottom: linear-gradient(180deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-left: linear-gradient(270deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-top-right: linear-gradient(45deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-bottom-right: linear-gradient(135deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-top-left: linear-gradient(225deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-bottom-left: linear-gradient(315deg, #22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);
$gradient-radial: radial-gradient(#22272Aff, #e8e7e6ff, #290025ff, #9792e3ff, #84b39aff);


/* Fonts */

