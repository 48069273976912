@use './styles/fonts.scss' as *;
@use './styles/variables.scss' as *;
@use './styles/default.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

.logo__wrapper {
    position: relative;
    z-index: 2;

    .logo__container {
        position:relative;
        z-index: 2
    }

    &::before {
        content: '';
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background: linear-gradient(90deg, #{$cambridge-blue} 20%, #{$tropical-indigo});
        border-radius: 90px;
        position: absolute;
        display: block;
        left: -3px;
        top: -3px;
        z-index: 1
    }
}

.separator {
    position: relative;

    &::before,
    &::after {
        content: '';
        display: block;
        width: theme('spacing.32');
        height: 2px;
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - theme('spacing.24'));
        background-color: $tropical-indigo;
        border-radius: 4px;
    }

    &::after {
        top: auto;
        bottom: calc(50% - 6px);
        left: auto;
        right: calc(50% - theme('spacing.24'));
        background-color: $cambridge-blue;
    }
}



